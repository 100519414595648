<template>
  <div class="index">
    <!--  头部-->
    <!--  <IndexHeader >
      <div class="tab">

        <div @click="toLogin" class="select">首页</div>

        <div class="stick"></div>

        <div @click="toLogin" >课程作业</div>

        <div class="stick"></div>

        <div @click="toLogin" >我要学习</div>

        <div class="stick"></div>

        <div @click="toLogin" >难题解答</div>

      </div>
      &lt;!&ndash;      铃铛&ndash;&gt;
      <div class="Bell" v-show="!this.$store.state.user.token">
        <i class="el-icon-message-solid"></i>
      </div>
      &lt;!&ndash;             边框&ndash;&gt;
      <div class="stick" style="margin-left: 20px;" v-show="!this.$store.state.user.token"></div>
      &lt;!&ndash;      登录&ndash;&gt;
      <div class="HeaderLogin" v-if="!this.$store.state.user.token">
        <div class="Login"  @click="toLogin"><a href="#">登录</a></div>
      </div>
    </IndexHeader>-->

    <div class="Box">
      <!--    ×-->
      <div class="Cha" @click="close()"><i class="el-icon-arrow-left" /><span>返回</span></div>
      <!--    标题-->
      <div class="Title">{{ detailContent.title }}</div>
      <!--    时间-->
      <div class="Time">
        <div>
          发布时间：{{ detailContent.createTime }}
        </div>
        <div>
          发布人：{{ detailContent.createBy }}
        </div>
      </div>
      <!--    内容-->
      <div style="width: 1200px;padding: 0 30px;margin-bottom: 30px">
        <div v-if="type==0" class="htmlContent ql-editor" v-html="detailContent.content" />
      </div>

      <div v-if="type==1" style="margin: 30px;padding-bottom: 30px;"> <img style="width: 1040px" :src="$downloadUrl+detailContent.detailUrls" alt=""></div>
      <!--    格子-->
      <!--      <div class="grid"></div>-->
    </div>
    <indexBottom />
  </div>
</template>

<script>
import { getDetailInfo } from '../../api/welcome'
import indexBottom from '../../components/indexBottom'
import { mapState } from 'vuex'
export default {
  name: 'Dynamicpages',
  components: {
    indexBottom
  },
  computed: {
    ...mapState({
      userInfo: state => state.user.userInfo
    })
  },
  watch: {
    userInfo() {
      this.getList()
    }
  },
  created() {
    this.pageId = this.$route.query.id
    // type（0公司动态，1课程宣传）
    this.type = this.$route.query.type
    this.onLoad()
  },
  data() {
    return {
      pageId: '',
      type: 0,
      detailContent: {},
      dialogVisible: false,
      total: 0,
      detail: {},
      phone: '',
      adDetail: {},
      backShow: false,
      msgAllShow: false,
      detailShow: false,
      adDetailShow: false,
      allTotal: 0,
      list: [],
      listAll: [],
      isShowGoTop: false,
      initPage: {
        pageNum: 1,
        pageSize: 6
      }
    }
  },
  methods: {
    onLoad() {
      this.getDynamicpages()
    },
    getDynamicpages() {
      const obj = {
        id: this.pageId,
        type: this.type
      }
      getDetailInfo(obj).then(res => {
        console.log(res)
        if (res.code === 200) {
          if (res.data.createTime == null) {
            res.data.createTime = '暂无发布日期'
          } else {
            res.data.createTime = res.data.createTime.substring(0, 10)
          }
          this.detailContent = res.data
        }
      })
    },
    // 关闭详情
    close() {
      this.$router.go(-1)
    },
    toLogin() {
      this.dialogVisible = true
    }
  }
}
</script>

<style scoped lang="less">
.select{
  width: 100px;
  height: 30px;
  //background:rgba(12, 3, 7, .3);
  //border-radius: 15px;
  color: rgba(67, 146, 253, 1) !important;
  font-weight: bold;
}
/*.Bell {
  float: left;
  //margin-left: 284px;
  margin: 23px 0 24px 248px;
  width: 14px;
  height: 18px;
  line-height: 25px;
  color:#4C97FD;
  //border-right: 1px solid #E7EDF6;
  //padding-right: 21px;
}
.tab {
  margin-left: 122px;

  .select{
    width: 100px;
    height: 30px;
    color: rgba(67, 146, 253, 1) !important;
    font-weight: bold;
  }

}*/
.stick {
  float: left;
  margin-top: 27px;
  //width: 0px;
  height: 16px;
  border-right: 1px solid #E7EDF6;
  //margin-left: 21px;
}
//  登录
.HeaderLogin {
  float: left;
  overflow: hidden;
  margin-top: 24px;
  margin-left: 21px;
  font-family: MicrosoftYaHei;
  .Login {
    float: left;
    color: #333333;
  }

  .Resign {
    float: right;
    font-size: 15px;
  }
}
.index{
  ::v-deep.el-dialog{

    /*max-height: 600px;*/
    min-height: 500px;
    /*background: #761717;*/
  }
  .user-avatar{
    width: 100%;
    height: 100%;
    border-radius: 50%;
  }
  >.top{
    //height: 80px;
    height: 70px;
    width: 100% !important;
    padding-right: 14px!important;
    background: #ffffff;
    position: fixed;
    top: 0;
    z-index: 99;
    .box{
      cursor: pointer;
      margin: 0 auto;
      //padding: 10px 0;
      width: 1200px;
      height: 70px;
      //height: 100%;
      //display: flex;
      //align-items: center;
      //justify-content: space-between;
    }
    .logo{
      float: left;
      //margin-left: 13px;
      padding: 12px 0 11px 19px;
      width: 53px;
      height: 55px;
      img {
        width: 53px;
        height: 55px;
      }
      //font-size: 30px;
      //font-family: JBeiWeiKaiSu;
      //align-items: center;
      //display: flex;
      //font-weight: 400;
      //color: #FFFFFF;
      //line-height: 72px;
      //p{
      //    margin: 0 10px;
      //}
    }
    .name {
      float: left;
      height: 20px;
      font-size: 20px;
      font-family: HYk2gj;
      color: #4C97FD;
      line-height: 70px;
      margin-left: 32px;
    }
    .tab{
      //display: flex;
      //width: 400px;
      float: left;
      height: 70px;
      margin-left: 122px;

      //line-height: 70px;
      //margin-left: -300px;
      /*justify-content: space-around;*/
      div{
        float: left;
        //margin-left: 30px;
        height: 70px;
        line-height: 70px;
        width: 114px;
        font-size: 16px;
        font-family: MicrosoftYaHei-Bold, MicrosoftYaHei;
        //margin-left: 20px;
        //font-family: Microsoft YaHei;
        //font-weight: 400;
        color: rgba(48, 48, 48, 1);
      }
      .stick {
        float: left;
        margin-top: 27px;
        height: 16px;
        width: 1px;
        background: #E7EDF6;
      }

      div:hover{
        background-color: #EDF4FD;
        font-weight: bold;
        color: #4C97FD;
      }
    }
    .info{
      float: left;
      height: 70px;
      display: flex;
      justify-content: center;
      align-items: center;
      padding-left: 183px;
      /deep/.el-badge__content.is-fixed {
        right: 118px!important;
      }
      .Bell {
        //margin-top:  8px;
        float: left;
        color:#4C97FD;
        width: 14px;
        height: 18px;
        //font-size: 16px;
      }
      .stick {
        //margin-top:  8px;
        float: left;
        margin-left: 21px;
        width: 1px;
        height: 16px;
        background: #E7EDF6;
      }
      /*   .el-icon-bell {
           //margin-right: 20px;
           //margin: 23px 0 24px 248px;
           width: 14px;
           height: 18px;
           //background: #4C97FD;
           //line-height: 25px;

           padding-left: 248px;
           border-right: 1px solid #CBD2D6;
           font-size: 16px;
         }*/
    }
    .info:hover{
      cursor: pointer;
    }
  }
  .rightTab{
    z-index: 9999;
    position: fixed;
    right: 30px;
    top: 420px;
    width: 50px;
    //height: 152px;
    background: #FFFFFF;
    border: 1px solid #E6E9EF;
    border-radius: 6px;
    .box{
      width: 48px;
      height: 50px;
      border-bottom: 1px solid #E6E9EF;
      overflow: hidden;
      //cursor: pointer;
    }
    .before{
      color: #CBD1D5;
      text-align: center;
      font-size: 20px;
      line-height: 50px;
    }
    .after,a{
      background: #39A5EF;
      width: 48px;
      height: 50px;
      font-size: 15px;
      display: table-cell;
      vertical-align:middle;
      text-align: center;
      font-family: Microsoft YaHei;
      font-weight: 400;
      color: #FFFFFF;
    }
    .feedback,.phoneNum{
      width: 48px;
      height: 100px;
      &:hover {
        transform: translate(0,-50%);
      }
    }
    .top{
      width: 48px;
      height: 100px;
      &:hover {
        transform: translate(0,-50%);
      }
    }
  }
}
.Cha {
  position: absolute;
  left: 30px;
  top: 30px;
  //width: 76px;
  height: 90px;
  cursor: pointer;
  .el-icon-arrow-left {
    width: 16px;
    height: 16px;
  }
  span {
    margin-left: 10px;
  }
}
.Box {
  position: relative;
  width: 1200px;
  margin: 70px auto 0;
  background: rgba(255, 255, 255, 1);
  //background-color: black;
  //  ×
  //.Cha {
  //  position: absolute;
  //  right: 30px;
  //  top: 30px;
  //  width: 16px;
  //  height: 16px;
  //}

  //标题
  .Title {
    padding-top: 80px;
    text-align: center;
    color: rgba(48, 48, 48, 1);
    font-size: 24px;
  }

  //时间
  .Time {
    display: flex;
    justify-content: space-evenly;
    margin: 20px 0;
    color: rgba(0, 0, 0, 0.25);
    font-size: 14px;
  }

  //内容
  .Content {
    width: 1040px;
    height: 800px;
    color: rgba(48, 48, 48, 1);
    font-size: 16px;
    line-height: 26px;
    margin: 40px auto 0;
    text-align: left;
    overflow: auto;
  }

  //格子
  .grid {
    width: 1040px;
    height: 400px;
    background: rgba(196, 196, 196, 1);
    margin: 30px auto 0;
  }
  .htmlContent{
    padding-bottom: 30px;
    /deep/ img{
      width: 1040px!important;
    }
  }
}
</style>
